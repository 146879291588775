@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  color: rgb(var(--foreground-rgb));
}

/* Fixes header overlapping anchor (e.g. #header-id) */
:target {
  padding-top: 150px; /* Adjust this value based on your fixed header height */
  margin-top: -150px; /* Negative margin to counteract the padding */
  position: relative;
  /* z-index: -1; */
}

.text-balance {
  text-wrap: balance;
}

.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: transparent;
  height: 100%;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
}
